import { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import AppRoutes from "./components/appRoutes";
import React from "react";
var App = function () {
    return (React.createElement(Suspense, null,
        React.createElement(Toaster, null),
        React.createElement(AppRoutes, null)));
};
export default App;
