import { lazy } from "@loadable/component";
import React from "react";
var AIProfiles = lazy(function () { return import("../pages/AIProfilesList"); });
var AIProfile = lazy(function () { return import("../pages/AIProfile"); });
var routes = [
    {
        path: "/",
        element: React.createElement(AIProfiles, null),
    },
    {
        path: "/:profileName",
        element: React.createElement(AIProfile, null)
    }
];
export default routes;
