import { Routes, Route } from "react-router-dom";
import routes from "../config/routes";
import React from "react";
var AppRoutes = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(Routes, null, routes.map(function (_a, i) {
            var path = _a.path, element = _a.element;
            return (React.createElement(Route, { index: true, key: i, path: path, element: element }));
        }))));
};
export default AppRoutes;
